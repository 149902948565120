import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle, useMemo, } from "react";
import Slider from "rc-slider";
import { FormattedMessage, useIntl } from "react-intl";
import "rc-slider/assets/index.css";

import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import Info from "../../icons/Info.js";
import ConformationModal from "../../components/ConfirmationModal/ConfirmationModal";
import CloseIcon from "./../../icons/CloseIcon.svg";
import { getErrorRefToScrollIntoView, generateNumberArray } from "../../utils";
import { Context } from "../../containers/Wrapper/Wrapper";
import { LandingPageContext } from "./LandingPage";
import "./suitability.css";
const Sutability = sessionStorage.getItem("Suitability");
const initialSliderValues = {
  cash: 100,
  realState: 0,
  privateEquity: 0,
  fixedIncome: 0,
  equality: 0,
};
const question24State = [
  { id: 0, message: "q.twentyfour1", status: false },
  { id: 1, message: "q.twentyfour2", status: false },
  { id: 2, message: "q.twentyfour3", status: false },
  { id: 3, message: "q.twentyfour4", status: false },
];
const sliderRailStyle = { backgroundColor: "#BCBCBC", height: "8px" };
const sliderTrackStyle = { backgroundColor: "#B89854", height: "8px" };

const getMemoizedInitialValues = () => {
  if(sessionStorage.getItem("Suitability")) {
    return {
      ...JSON.parse(sessionStorage.getItem("Suitability"))
    }
  }
  return {
    ques20: "",
    //ques20: "",
    ques20Assets: "",
    ques21Total: "",
    ques21Intl: "",
    ques21Cash: "",
    ques21RealEstate: "",
    ques21PvtEquity: "",
    ques21FixedIncome: "",
    ques21Equity: "",
    ques22: "",
    ques23: "",
    ques24: "",
  }
}

const Suitability = forwardRef((props, ref) => {
  const context = useContext(Context);
  const LandingContext = useContext(LandingPageContext);
  const currentTabName = "Suitability";
  const { handleActiveTab, formikValues, setFormValues, handleSummeryModal } = props;
  // const currentTabVals = formikValues[currentTabName];
  const memoizedInitialValues = useMemo(() => getMemoizedInitialValues(), [])
  const currentTabVals = memoizedInitialValues ? memoizedInitialValues : LandingContext.suitabilityValues ? LandingContext.suitabilityValues : {};
  const [slider, setSlider] = useState(initialSliderValues);
  const [setyesOrno, setyesorNo] = useState(false);
  const intl = useIntl();
  const [click, setClicked] = useState(false);
  const [qTwentyFour, setqTwentyFour] = useState(question24State);
  const [remainingvalue2, setremainingvaluedata2] = useState("");
  const handleYesOrNo = (value) => {
    setyesorNo(value);
  };
  const nonSchemaErrorsInitial = {
    totalnetworth: false,
    totalnetworthIntl: false,
  };

  const scrollToTop = () => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
  };

  useEffect(() => {
    let temp = document.getElementsByClassName("main-container");
    if (temp.length > 0) temp[temp.length - 1].scrollTo(0, 0);
    LandingContext.setSuitabilitySubmitBack(false);
  }, []);

  const [selected, setSelected] = useState({
    qTwenty: "",
    qTwentyTwo: "",
    qTwentyThree: "",
    qTwentyFour: "",
  });
  let remainingvalue = 0;
  const handleChange = (e) => {
    if (e.target.value.length === 0) {
      remainingvalue = 0;
      setremainingvaluedata2(remainingvalue);
    } else {
      remainingvalue = remainingvalue + e.target.value.length;
      setremainingvaluedata2(remainingvalue);
    }
  };

  const [twentyOne, setTwentyOne] = useState({ first: "", second: "" });
  const handeletwentyOne = (e) => {
    const { name, value } = e.target;

    if (value <= 100) {
      setTwentyOne((prv) => ({ ...prv, [name]: value }));
    }
  };
  const [additionaldata, setadditionaldata] = useState(false);
  const testdata = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      setadditionaldata(true);
      setSelected("10");

      setClicked(true);
    } else {
      setSelected("13");

      setClicked(true);
      setadditionaldata(false);
    }
  };
  const genError = () => {
    return <FormattedMessage id="error.gen" defaultMessage="This field is required" />;
  };
  useEffect(() => {
    if (
      currentTabVals.ques21Cash >= 0 &&
      currentTabVals.ques21RealEstate >= 0 &&
      currentTabVals.ques21PvtEquity >= 0 &&
      currentTabVals.ques21FixedIncome >= 0 &&
      currentTabVals.ques21Equity >= 0
    ) {
      setSlider({
        cash: currentTabVals.ques21Cash,
        realState: currentTabVals.ques21RealEstate,
        privateEquity: currentTabVals.ques21PvtEquity,
        fixedIncome: currentTabVals.ques21FixedIncome,
        equality: currentTabVals.ques21Equity,
      });
    }
    setSelected({
      ...selected,
      qTwenty: currentTabVals.ques20 === "yes" ? "1" : "2",
    });
    if (!currentTabVals.ques24 || (currentTabVals.ques24 && currentTabVals.ques24.length < 4)) {
      setqTwentyFour(question24State);
    } else {
      setqTwentyFour(currentTabVals.ques24);
    }
  }, []);

  let qNumbers = context.isClient ? generateNumberArray(19, 23) : generateNumberArray(20, 24);
  
  const formik = useFormik({
    initialValues: memoizedInitialValues,
    initialTouched: {
      ques20: true,
      ques20Assets: true,
      ques21Total: true,
      ques21Intl: true,
      ques21Cash: true,
      ques21RealEstate: true,
      ques21PvtEquity: true,
      ques21FixedIncome: true,
      ques21Equity: true,
      ques22: true,
      ques23: true,
      ques24: true,
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      ques20: Yup.string().required(genError),
      ques20Assets: Yup.string().when("ques20", {
        is: "yes",
        then: Yup.string().required(genError),
      }),
      ques21Total: Yup.number().required(genError),
      ques21Intl: Yup.number().required(genError),
      ques21Cash: Yup.string().notRequired(),
      ques21RealEstate: Yup.string().notRequired(),
      ques21PvtEquity: Yup.string().notRequired(),
      ques21FixedIncome: Yup.string().notRequired(),
      ques21Equity: Yup.string().notRequired(),
      ques22: Yup.string().required(genError),
      ques23: Yup.string().required(genError),
      ques24: Yup.array().notRequired(),
    }),

    onSubmit: (values) => {
      LandingContext.setSuitabilityValues(values);
      sessionStorage.setItem("Suitability", JSON.stringify(values));
      if(!LandingContext.suitabilitySubmitBack) {
        setFormValues(values);
        handleSummeryModal();
      }
      // if (!context.isClient) {
      //   setFormValues(values);
      //   handleActiveTab("Personal Information");
      // } else {
      //   setFormValues(values);
      //   handleSummeryModal();
      // }
    },
    validateOnMount: true,
  });
  useImperativeHandle(ref, () => ({
    validateSuitability: () => {
      return {
        ...formik,
      };
    },
    submitSuitability: () => {
      return formik.submitForm();
    },
  }));
  useEffect(() => {
    formik.setFieldValue("ques21Cash", slider.cash);
    formik.setFieldValue("ques21RealEstate", slider.realState);
    formik.setFieldValue("ques21PvtEquity", slider.privateEquity);
    formik.setFieldValue("ques21FixedIncome", slider.fixedIncome);
    formik.setFieldValue("ques21Equity", slider.equality);
  }, [slider]);

  useEffect(() => {
    if (formik.isSubmitting) {
      let errors = Object.keys(formik.errors);
      if (errors.length > 0) {
        getErrorRefToScrollIntoView(errors[0]);
      }
    }
  }, [formik.isSubmitting]);

  const reassignSliders = (field, value) => {
    let cashValue;
    switch (field) {
      case "realState":
        cashValue = 100 - value - slider.privateEquity - slider.fixedIncome - slider.equality;
        if (cashValue >= 0) {
          setSlider({ ...slider, cash: cashValue, realState: value });
        } else {
          setSlider({
            ...slider,
            cash: 0,
            realState: value - Math.abs(cashValue),
          });
        }
        return;
      case "privateEquity":
        cashValue = 100 - slider.realState - value - slider.fixedIncome - slider.equality;
        if (cashValue >= 0) {
          setSlider({ ...slider, cash: cashValue, privateEquity: value });
        } else {
          setSlider({
            ...slider,
            cash: 0,
            privateEquity: value - Math.abs(cashValue),
          });
        }
        return;
      case "fixedIncome":
        cashValue = 100 - slider.realState - slider.privateEquity - value - slider.equality;
        if (cashValue >= 0) {
          setSlider({ ...slider, cash: cashValue, fixedIncome: value });
        } else {
          setSlider({
            ...slider,
            cash: 0,
            fixedIncome: value - Math.abs(cashValue),
          });
        }
        return;
      case "equality":
        cashValue = 100 - slider.realState - slider.privateEquity - slider.fixedIncome - value;
        if (cashValue >= 0) {
          setSlider({ ...slider, cash: cashValue, equality: value });
        } else {
          setSlider({
            ...slider,
            cash: 0,
            equality: value - Math.abs(cashValue),
          });
        }
        return;
      default:
        return;
    }
  };
  const handleMultiSelect = (value) => {
    setqTwentyFour((prev) => {
      const prevvalue = prev.map((item) => {
        return item.id === value ? { ...item, status: !item.status } : item;
      });

      const q24 = [...qTwentyFour];
      q24.map((e) => {
        if (e.id === value) {
          return (e.status = !e.status);
        }
      });
      formik.setFieldValue("ques24", q24);
      return prevvalue;
    });
  };

  const numeralMap = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const formatNumbers = (value) => {
    if (!value && value !== "0") return "";
  
    const westernValue = value
      .toString()
      .split("")
      .map((char) => numeralMap[char] || char)
      .join("");
  
    const numericValue = westernValue.replace(/[^\d.]/g, "");
  
    const number = parseFloat(numericValue);
    if (isNaN(number)) return value;
  
    return new Intl.NumberFormat().format(number);
  };
  

  const setFormikValue = (e) => {
    const percentval = formatNumbers(e.target.value);
    formik.setFieldValue(e.target.name, percentval);

    if (e.target.name === "ques21Total") {
      if (percentval > 69) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          totalnetworth: true,
        });
      } else {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          totalnetworth: false,
        });
      }
    } else if (e.target.name === "ques21Intl") {
      if (percentval > 100) {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          totalnetworthIntl: true,
        });
      } else {
        setNonSchemaErrors({
          ...nonSchemaErrors,
          totalnetworthIntl: false,
        });
      }
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen((prev) => !prev);

  const modalContent = (
    <>
      <div className="modalcontent">
        <img style={{ cursor: "pointer" }} src={CloseIcon} alt="" className="close-cross" onClick={handleModal} />

        <p style={{ textAlign: "center", fontSize: "25px", fontWeight: "200" }}>Net Wealth</p>
        <p className="textcolor">
          <FormattedMessage id="q.twentyone.modaltext" defaultMessage="q.twentyfirst" />
        </p>
      </div>
    </>
  );
  const [nonSchemaErrors, setNonSchemaErrors] = useState(nonSchemaErrorsInitial);
  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <section>
        <p>
          <span className="title headercontent">
            <FormattedMessage id="q.twenty" defaultMessage="q.twenty" values={{ qNumber: qNumbers[0] }} />
          </span>
        </p>
        <p className="margintopn15">
          <span className="smallcontent">
            <FormattedMessage id="q.twentysub" defaultMessage="q.twentysub" />
          </span>
        </p>
        <Row className="ans-row">
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                name="ques20"
                value="yes"
                defaultChecked={formik.values.ques20 === "yes"}
                onClick={(e) => {
                  setFormikValue(e);
                  setSelected({ ...selected, qTwenty: "1" });
                }}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="yes.suitability" defaultMessage="yes.suitability" />
            </label>
          </Col>
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                name="ques20"
                value="no"
                defaultChecked={formik.values.ques20 === "no"}
                onClick={(e) => {
                  setFormikValue(e);
                  setSelected({ ...selected, qTwenty: "2" });
                }}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="no.suitability" defaultMessage="no.suitability" />
            </label>
          </Col>
        </Row>
        {formik.touched.ques20 && formik.errors.ques20 ? (
          <div className="validation-error margintopn8">{formik.errors.ques20}</div>
        ) : (
          ""
        )}
        {/* {setyesOrno && (
                <textarea className='sutability-textArea' maxLength='200' />
            )} */}
        <br />
        {selected.qTwenty == "1" ? (
          <div>
            <Form.Control
              as="textarea"
              maxLength="200"
              name="ques20Assets"
              placeholder={intl.formatMessage({
                description: "suitability.note.placeholder",
                id: "suitability.note.placeholder",
              })}
              defaultValue={formik.values.ques20Assets}
              onChange={(e) => {
                setFormikValue(e);
                handleChange(e);
              }}
              style={{
                width: "100%",
                height: "87px",
                backgroundColor: "#222",
                fontFamily: "Gotham",
                lineHeight: "22px",
                fontweight: 500,
                color: "#alalal!important",
                color: "#a1a1a1",
                boxShadow: "none",
                opacity: ".7",
              }}
            />
            <div align="right">
              <span id="current_count">{`${remainingvalue2 === "" ? 0 : remainingvalue2}`}</span>
              <span id="maximum_count">/ 200</span>
            </div>
            {formik.submitCount > 0 && formik.errors.ques20Assets ? (
              <div className="validation-error margintopn15">{formik.errors.ques20Assets}</div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <br />
        <p>
          {" "}
          <span className="title headercontent">
            <FormattedMessage id="q.twentyone1" defaultMessage="q.twentyone1" values={{ qNumber: qNumbers[1] }} />

            <input
              className="enter-amount"
              name="ques21Total"
              defaultValue={formik.values.ques21Total}
              onChange={setFormikValue}
            />
            <FormattedMessage id="q.twentyone2" defaultMessage="q.twentyone2" />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onMouseEnter={handleModal}
            onMouseLeave={handleModal}
            onClick={handleModal}
          >
            <Info />
          </span>
        </p>
        <p className="margintopn15">
          <span className="smallcontent">
            <FormattedMessage id="q.twentyone3" defaultMessage="q.twentyone3" />
          </span>
        </p>
        <div className="validation-error margintopn15">
          {/* {formik.errors.ques21Total === 'This field is required' ?
            (formik.isSubmitting) ? formik.errors.ques21Total : '' :
            formik.errors.ques21Total} */}

          {formik.touched.ques21Total && formik.errors.ques21Total ? (
            <div className="validation-errorq5">{formik.errors.ques21Total}</div>
          ) : (
            ""
          )}
          {nonSchemaErrors.totalnetworth ? (
            <div className="validation-error ">
              <FormattedMessage
                id="error.indicatedpercent70"
                defaultMessage="error.indicatedpercent70"
              ></FormattedMessage>
            </div>
          ) : (
            ""
          )}
        </div>
        <p className="margintop30">
          <input
            className="enter-amount"
            name="ques21Intl"
            defaultValue={formik.values.ques21Intl}
            onChange={setFormikValue}
          />
          <FormattedMessage id="q.twentyone4" defaultMessage="q.twentyone4" />
        </p>
        <p className="margintopn15">
          <span className="smallcontent ">
            <FormattedMessage id="q.twentyone5" defaultMessage="q.twentyone5" />
          </span>
        </p>
        {/* { formik.errors.ques21Intl ? (
          <div className="validation-error margintopn15">
            {formik.errors.ques21Intl}
          </div>
        ) : (
          ""
        )} */}
        <div className="validation-error margintopn15">
          {/* {formik.errors.ques21Intl === 'This field is required' ?
            (formik.isSubmitting) ? formik.errors.ques21Intl : '' :
            formik.errors.ques21Intl} */}
          {formik.touched.ques21Intl && formik.errors.ques21Intl ? (
            <div className="validation-errorq5">{formik.errors.ques21Intl}</div>
          ) : (
            ""
          )}
          {nonSchemaErrors.totalnetworthIntl ? (
            <div className="validation-error ">
              <FormattedMessage id="error.indicatedpercent" defaultMessage="error.indicatedpercent"></FormattedMessage>
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
        <p>
          <span className="title headercontent">
            <FormattedMessage id="q.twentyone6" defaultMessage="q.twentyone6" />
          </span>
        </p>
        <div className="sliderItem">
          <Row className="ans-row">
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <FormattedMessage id="q.twentyone7" defaultMessage="q.twentyone7" />
            </Col>
            <Col xs={8} sm={8} lg={8} className="ans-col">
              <div className="slider">
                <Slider
                  min={0}
                  max={100}
                  railStyle={sliderRailStyle}
                  trackStyle={sliderTrackStyle}
                  handleStyle={{ display: "none" }}
                  value={slider.cash}
                  reverse={context.locale === "ar"}
                />
              </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <div className="align-right">{slider.cash}%</div>
            </Col>
          </Row>
        </div>
        <div className="sliderItem">
          <Row className="ans-row">
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <FormattedMessage id="q.twentyone8" defaultMessage="q.twentyone8" />
            </Col>
            <Col xs={8} sm={8} lg={8} className="ans-col">
              <div className="slider">
                <Slider
                  min={0}
                  max={100}
                  railStyle={sliderRailStyle}
                  trackStyle={sliderTrackStyle}
                  handleStyle={{ backgroundColor: "#A28755", border: "none" }}
                  value={slider.realState}
                  onChange={(value) => reassignSliders("realState", value)}
                  reverse={context.locale === "ar"}
                />
              </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <div className="align-right">{slider.realState}%</div>
            </Col>
          </Row>
        </div>
        <div className="sliderItem">
          <Row className="ans-row">
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <FormattedMessage id="q.twentyone9" defaultMessage="q.twentyone9" />
            </Col>
            <Col xs={8} sm={8} lg={8} className="ans-col">
              <div className="slider">
                <Slider
                  min={0}
                  max={100}
                  railStyle={sliderRailStyle}
                  trackStyle={sliderTrackStyle}
                  handleStyle={{ backgroundColor: "#A28755", border: "none" }}
                  value={slider.privateEquity}
                  onChange={(value) => reassignSliders("privateEquity", value)}
                  reverse={context.locale === "ar"}
                />
              </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <div className="align-right">{slider.privateEquity}%</div>
            </Col>
          </Row>
        </div>
        <div className="sliderItem">
          <Row className="ans-row">
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <FormattedMessage id="q.twentyone10" defaultMessage="q.twentyone10" />
            </Col>
            <Col xs={8} sm={8} lg={8} className="ans-col">
              <div className="slider">
                <Slider
                  min={0}
                  max={100}
                  railStyle={sliderRailStyle}
                  trackStyle={sliderTrackStyle}
                  handleStyle={{ backgroundColor: "#A28755", border: "none" }}
                  value={slider.fixedIncome}
                  onChange={(value) => reassignSliders("fixedIncome", value)}
                  reverse={context.locale === "ar"}
                />
              </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <div className="align-right">{slider.fixedIncome}%</div>
            </Col>
          </Row>
        </div>
        <div className="sliderItem">
          <Row className="ans-row">
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <FormattedMessage id="q.twentyone11" defaultMessage="q.twentyone11" />
              <br /> <FormattedMessage id="q.twentyone12" defaultMessage="q.twentyone12" />
            </Col>
            <Col xs={8} sm={8} lg={8} className="ans-col">
              <div className="slider">
                <Slider
                  min={0}
                  max={100}
                  railStyle={sliderRailStyle}
                  trackStyle={sliderTrackStyle}
                  handleStyle={{ backgroundColor: "#A28755", border: "none" }}
                  value={slider.equality}
                  onChange={(value) => reassignSliders("equality", value)}
                  reverse={context.locale === "ar"}
                />
              </div>
            </Col>
            <Col xs={2} sm={2} lg={2} className="ans-col">
              <div className="align-right">{slider.equality}%</div>
            </Col>
          </Row>
        </div>
        <p className="margintop15">
          {" "}
          <span className="title headercontent">
            <FormattedMessage id="q.twentytwo" defaultMessage="q.twentytwo" values={{ qNumber: qNumbers[2] }} />
          </span>
        </p>
        <Row className="ans-row">
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                name="ques22"
                defaultChecked={formik.values.ques22 === "yes"}
                value="yes"
                onClick={setFormikValue}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="yes.suitability" defaultMessage="yes.suitability" />
            </label>
          </Col>
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                value="no"
                name="ques22"
                defaultChecked={formik.values.ques22 === "no"}
                onClick={setFormikValue}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="no.suitability" defaultMessage="no.suitability" />
            </label>
          </Col>
        </Row>
        {formik.touched.ques22 && formik.errors.ques22 ? (
          <div className="validation-error margintopn8">{formik.errors.ques22}</div>
        ) : (
          ""
        )}
        <br />
        <p className="margintop15">
          {" "}
          <span className="title headercontent">
            <FormattedMessage id="q.twentythree" defaultMessage="q.twentythree" values={{ qNumber: qNumbers[3] }} />
          </span>
        </p>
        <Row className="ans-row">
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                name="ques23"
                value="yes"
                defaultChecked={formik.values.ques23 === "yes"}
                onClick={setFormikValue}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="yes.suitability" defaultMessage="yes.suitability" />
            </label>
          </Col>
          <Col xs={12} sm={"auto"} className="ans-col">
            <label className="radio-wrapper">
              <input
                type="radio"
                name="ques23"
                value="no"
                defaultChecked={formik.values.ques23 === "no"}
                onClick={setFormikValue}
              />
              <span className="radio-btn"></span>
              <FormattedMessage id="no.suitability" defaultMessage="no.suitability" />
            </label>
          </Col>
        </Row>
        {formik.touched.ques23 && formik.errors.ques23 ? (
          <div className="validation-error margintopn8">{formik.errors.ques23}</div>
        ) : (
          ""
        )}
        <br />
        <p className="margintop15">
          {" "}
          <span className="title headercontent">
            <FormattedMessage id="q.twentyfour" defaultMessage="q.twentyfour" values={{ qNumber: qNumbers[4] }} />
          </span>
        </p>
        {qTwentyFour &&
          qTwentyFour.map((item) => (
            <Row className="ans-row" key={item.id}>
              <Col xs={12} sm={"auto"} className="ans-col mar-button">
                <label className="checkbox-wrapper ">
                  <input type="checkbox" checked={item.status} onClick={() => handleMultiSelect(item.id)} />
                  <span className="checkbox-btn"></span>
                  <FormattedMessage id={item.message} defaultMessage={item.message} />
                </label>
              </Col>
            </Row>
          ))}
        {/* {!context.isClient && (
          <ButtonContainer
            backAction={() => {
              setFormValues(formik.values);
              handleActiveTab("Investment Behaviour");
              scrollToTop();
            }}
            nextLabel="btn.personalInfo"
          />
        )} */}
        {
          <ButtonContainer
            backAction={() => {
              formik.errors = {}        
              setFormValues(formik.values);
              LandingContext.setSuitabilitySubmitBack(true);
              handleActiveTab("Investment Behaviour");
              scrollToTop();
            }}
            nextLabel="per.info.btn.viewSummary"
          />
        }
        {
          <div className="Para margintop30" style={{ color: "#aaa" }}>
            <FormattedMessage id="per.info.Para1" defaultMessage="per.info.Para1" />
            <br />
            <br />
            <FormattedMessage id="per.info.Para2" defaultMessage="per.info.Para2" />
          </div>
        }
        {/* {!context.isClient && (
        <ButtonContainer
          backAction={() => {
            setFormValues(formik.values);
            handleActiveTab("Investment Behaviour");
          }}
          nextLabel="per.info.btn.viewSummary"
        />
        )} */}
        {/* <ButtonContainer backAction={()=>handleActiveTab("Investment Behaviour")} nextLabel="btn.personalInfo" /> */}
        <ConformationModal showModal={modalOpen} closeCallback={handleModal} content={modalContent}>
          {/* <button
          variant="secondary"
          // onClick={handleModal}
          className="agreeAndContinue"
        >
          <FormattedMessage id="header.modal.Close" defaultMessage="header.modal.Close" />
        </button> */}
        </ConformationModal>
      </section>
    </form>
  );
});
export default Suitability;
